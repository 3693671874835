import { ref, computed } from "@vue/composition-api";

import { requestListOrganizations, requestSaveEditOrganization } from "@/service/UserService";

const orgList = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getOrgList = computed(() => orgList.value);

export const cancelNewItem = async (id) => {
  var index = orgList.value.findIndex((i) => i.OrganizationId === id);
  if (index !== -1) {
    orgList.value.splice(index, 1);
  }
};

export const removeNotAddedItems = () => {
  orgList.value = orgList.value.filter((i) => !i.isNew);
};

export const addNewItem = async (data) => {
  orgList.value.unshift(data);
};

export const validateIfDetailsOpen = () => {
  console.log(orgList);
  orgList.value.find(o => o._showDetails);
}

export const onLoadAllOrgs = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { organizations, pagination }
    } = await requestListOrganizations(reqData);

    if (organizations) {
      orgList.value.splice(0, orgList.value.length);
      orgList.value.push(...organizations);
    } else {
      orgList.value.splice(0, orgList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const saveEditOrganizationItem = async (data) => {
  try {
    loading.value = true;
    await requestSaveEditOrganization(data);
    await onLoadAllOrgs({ reset: true });
  } finally {
    loading.value = false;
  }
};
