import { ref, computed } from "@vue/composition-api";

import {
  requestListFacilities,
  requestLoadUsers,
  requestPasswordResetEmail,
  requestSaveEditUser,
  requestUpdateUser,
  loadQueueData,
  processContactInQueue,
  loadHOFContactDocsListRequest,
  loadHofDocumentImage
} from "@/service/UserService";
import { onLoadAllOrgs } from "@/views/organization/orgRequests";
import { requestListAllOrgs } from "@/service/ListoService";

export const usersList = ref([]);
const queuedList = ref([]);
export const facilitiesList = ref([]);
export const orgList = ref([]);
export const getAllOrgs = computed(() => orgList.value);
export const filterBy = ref(null);
export const sortBy = ref("lastLogin");
export const sortOrder = ref("asc");
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getUserList = computed(() => usersList.value);
export const getQueued = computed(() => queuedList.value);
export const getFacilityList = computed(() => facilitiesList.value);

export const addNewItem = async (data) => {
  usersList.value.unshift(data);
};

export const validateIfDetailsOpen = () => {
  console.log(usersList);
  usersList.value.find((o) => o._showDetails);
};

export const cancelNewItem = async (id) => {
  var index = usersList.value.findIndex((i) => i.id === id);
  if (index !== -1) {
    usersList.value.splice(index, 1);
  }
};

export const onResetPasswordRequest = async (usr) => {
  try {
    loading.value = true;
    await requestPasswordResetEmail({ userId: usr });
  } finally {
    loading.value = false;
  }
};

export const loadDocumentImage = async (reqData) => {
  try {
    loading.value = true;
    console.log(reqData);
    const { data } = await loadHofDocumentImage(reqData);
    return data;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Image loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadHOFContactDocsList = async (contact) => {
  let reqData = {};
  reqData.contactUid = contact;
  return await loadHOFContactDocsListRequest(reqData);
};

export const loadQueue = async ({ prevPage, nextPage, reset, showAll }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value && sortBy.value.length > 0 ? sortBy.value : null,
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null,
      showAll: showAll
    }
  };
  loading.value = true;
  try {
    const {
      data: { queued, pagination }
    } = await loadQueueData(reqData);
    if (queued) {
      queuedList.value.splice(0, queuedList.value.length);
      queuedList.value.push(...queued);
    } else {
      queuedList.value.splice(0, queuedList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
    return null;
  } catch (err) {
    return err.response.statusText || "Server Error";
  } finally {
    loading.value = false;
  }
};

export const processQueued = async (id) => {
  loading.value = true;
  try {
    return await processContactInQueue({ contactUid: id });
  } finally {
    loading.value = false;
  }
};

export const loadAllOrgs = async () => {
  loading.value = true;
  try {
    const {
      data: { organizations }
    } = await requestListAllOrgs();

    if (organizations) {
      orgList.value.splice(0, orgList.value.length);
      orgList.value.push(...organizations);
    } else {
      orgList.value.splice(0, orgList.value.length);
    }
  } finally {
    loading.value = false;
  }
};

export const onLoadAllUsers = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value && sortBy.value.length > 0 ? sortBy.value : null,
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { allUsers, pagination }
    } = await requestLoadUsers(reqData);
    if (allUsers) {
      usersList.value.splice(0, usersList.value.length);
      usersList.value.push(...allUsers);
    } else {
      usersList.value.splice(0, usersList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const updateUser = async (data) => {
  try {
    loading.value = true;
    await requestUpdateUser(data);
  } finally {
    loading.value = false;
  }
};

export const saveEditUserItem = async (data) => {
  try {
    loading.value = true;
    await requestSaveEditUser(data);
    await onLoadAllOrgs({ reset: true });
  } finally {
    loading.value = false;
  }
};

export const loadFacilitiesForUser = async () => {
  try {
    loading.value = true;
    const {
      data: { facilities }
    } = await requestListFacilities({});
    facilitiesList.value = facilities;
  } finally {
    loading.value = false;
  }
};
