<template>
  <b-card no-body title="Users">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button
            v-b-tooltip.hover
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            title="Add new user"
            @click="addNewUserItem"
            :disabled="getLoading || !isUserAdmin"
          >
            <feather-icon icon="FilePlusIcon" />
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllUsers" v-b-tooltip.hover title="Refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getUserList" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(fullName)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value ? data.value : "Name not set" }}
          </b-link>
          <br />
          <span style="font-size: 0.5em !important"> {{ data.item.facilities ? data.item.facilities.length : "No" }} facility mapping(s) </span>
          <b-button
            v-b-tooltip.hover
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            small
            title="Send reset password email"
            variant="flat-warning"
            class="btn-icon rounded-circle"
            @click="requestPasswordReset(data)"
          >
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="PenToolIcon" />
          </b-button>
        </template>
        <template #cell(username)="data">
          {{ data.value }}<br />
          <small>({{ data.item.organizationName }})</small>
          <br />
          <small>Created: {{ showDateInFormat(data.item.created) }}</small>
        </template>

        <template #cell(lastLogin)="data">
          <b>Last Login:</b> {{ data.value ? showDateInFormat(data.value) : "Never" }}<br />

          <b>Last Action:</b> {{ data.item.eventAction }}<br />
          <b>At:</b> {{ data.item.eventTimestamp ? showDateInFormat(data.item.eventTimestamp) : "" }}<br />
        </template>
        <template #cell(attributes)="data">
          <b-form-checkbox
            v-if="!data.item.isNew"
            :checked="data.item.active"
            class="custom-control-success"
            name="active-button"
            :title="'Click to ' + (data.item.active ? 'disable' : 'enable') + ' user.'"
            v-b-tooltip.hover.left
            @change="inactivateUser($event, data.item, 'ACTIVE')"
            switch
            :disabled="!isUserAdmin"
          />
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-row>
            <b-col md="1" />
            <b-col md="9">
              <b-form inline>
                <b-form-group id="userIsAdmin" label-for="userIsAdmin" label-cols="auto" label-align="right">
                  <template #label>
                    <b>Admin: </b>
                  </template>
                  <b-form-checkbox
                    v-if="!row.item.isNew"
                    :checked="row.item.admin"
                    class="custom-control-success"
                    name="admin-access-button"
                    :title="'Click to ' + (row.item.admin ? 'disable' : 'enable') + '.'"
                    v-b-tooltip.hover.left
                    @change="inactivateUser($event, row, 'ADMIN')"
                    id="userIsAdmin"
                    switch
                    :disabled="!isUserAdmin"
                  />
                </b-form-group>
                <b-form-group id="userIsSupport" label-for="userIsSupport" label-cols="auto" label-align="right">
                  <template #label>
                    <b>Support</b>
                  </template>

                  <b-form-checkbox
                    v-if="!row.item.isNew"
                    :checked="row.item.supportAgent"
                    class="custom-control-success"
                    name="support-agent-button"
                    :title="'Click to ' + (row.item.supportAgent ? 'disable' : 'enable') + '.'"
                    v-b-tooltip.hover.left
                    @change="inactivateUser($event, row, 'SUPPORT')"
                    id="userIsSupport"
                    switch
                    :disabled="!isUserAdmin"
                  />
                </b-form-group>
                <b-form-group id="userIsCompassion" label-for="userIsCompassion" label-cols="auto" label-align="right">
                  <template #label>
                    <b>Patient</b>
                  </template>
                  <b-form-checkbox
                    v-if="!row.item.isNew"
                    :checked="row.item.supportDonation"
                    class="custom-control-success"
                    name="compassion-patient-button"
                    :title="'Click to ' + (row.item.supportDonation ? 'disable' : 'enable') + '.'"
                    v-b-tooltip.hover.left
                    @change="inactivateUser($event, row, 'COMPASSION')"
                    id="userIsCompassion"
                    switch
                    :disabled="!isUserAdmin"
                  />
                </b-form-group>
                <b-form-group id="userIsCompassionOperator" label-for="userIsCompassionOperator" label-cols="auto" label-align="right">
                  <template #label>
                    <b>Compassion Operator</b>
                  </template>
                  <b-form-checkbox
                    v-if="!row.item.isNew"
                    :checked="row.item.compassionOperator"
                    class="custom-control-success"
                    name="compassion-operator-button"
                    :title="'Click to ' + (row.item.compassionOperator ? 'disable' : 'enable') + '.'"
                    v-b-tooltip.hover.left
                    @change="inactivateUser($event, row, 'COMPASSIONOPERATOR')"
                    id="userIsCompassionOperator"
                    switch
                    :disabled="!isUserAdmin"
                  />
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <br />

          <b-row>
            <b-col md="1" />
            <b-col md="9">
              <template v-if="row.item.termsAndConditions">
                <span v-for="tcitem in row.item.termsAndConditions" :key="tcitem.termsConditionsUid">
                  <b>{{ tcitem.name }} : </b> ({{ tcitem.tcVersion }})
                  <template v-if="tcitem.accepted">
                    - <b><span class="text-success"> Accepted </span></b>
                  </template>
                  <template v-else>
                    - <b><span class="text-danger"> Not Accepted </span></b>
                  </template>
                  <p />
                </span>
              </template>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="1" />
            <b-col md="1">
              <b-button
                id="edit_user_button"
                class="btn-icon success"
                v-b-tooltip.hover
                variant="gradient-primary"
                title="Edit User Data"
                @click="editItem(row.item)"
                :disabled="!isUserAdmin"
              >
                Edit
              </b-button>
            </b-col>
            <b-col md="1">
              <b-button
                id="user_timeline_button"
                class="btn-icon success"
                v-b-tooltip.hover
                variant="gradient-primary"
                title="Show User Timeline"
                @click="onUserTimelineClick(row.item)"
              >
                Timeline
              </b-button>
            </b-col>
          </b-row>

          <br />
          <b-card title="Mapped Facilities" sub-title="">
            <b-col md="6">
              <ol id="mapped_facilities">
                <li v-for="item in row.item.facilities" :key="item.facilityUid">
                  {{ item.listoDba }} {{ item.licenseNumber }} <br /><small> [{{ item.name }}]</small>
                </li>
              </ol>
            </b-col>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getUserList,
  getFacilityList,
  filterBy,
  sortBy,
  sortOrder,
  setPerPage,
  getLoading,
  onResetPasswordRequest,
  onLoadAllUsers,
  updateUser,
  getTotalRows,
  getPerPage,
  getAllOrgs,
  addNewItem,
  saveEditUserItem,
  validateIfDetailsOpen,
  cancelNewItem,
  loadFacilitiesForUser,
  loadAllOrgs
} from "@/views/users/userRequests";
import { getUserData } from "@/auth/utils";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, reactive, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { useRouter } from "@core/utils/utils";

export default {
  name: "AllUsers",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);
    const selectedUser = reactive({
      value: null
    });
    const fields = [
      // 'mark_resolved',
      {
        key: "fullName",
        label: "Name"
      },
      "username",
      { key: "lastLogin", label: "History", sortable: true },
      { key: "attributes", label: "Active", sortable: true }
    ];

    const DEFAULT_FORM = {
      userUid: "",
      username: "",
      email: "",
      fullName: "",
      phone: "",
      metrcApiKey: "",
      organizationUid: "",
      isSupportAgent: false,
      isCompassion: false,
      facilityUids: [],
      facilities: [],
      selectedFacility: null,
      orgObject: null,
      defaultOrg: ""
    };

    const form = DEFAULT_FORM;
    let nextId = 99999;
    let updateFacListKey = ref(0);

    const editUserItem = ref(-1);

    const { router } = useRouter();

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const requestPasswordReset = async (req) => {
      onResetPasswordRequest(req.item.userUid);
    };

    const initalLoadUsers = async () => await onLoadAllUsers({ prevPage: false, nextPage: false, reset: true });

    const inactivateUser = async (newVal, item, flag) => {
      let req = {};
      req.forUserUid = item.userUid;

      switch (flag) {
        case "ACTIVE":
          req.updateType = "ACTIVE";
          req.updateBoolean = newVal;
          break;
        case "ADMIN":
          req.updateType = "ADMIN";
          req.updateBoolean = newVal;
          break;
        case "SUPPORT":
          req.updateType = "SUPPORT";
          req.updateBoolean = newVal;
          break;
        case "COMPASSION":
          req.updateType = "COMPASSION";
          req.updateBoolean = newVal;
          break;
        case "COMPASSIONOPERATOR":
          req.updateType = "COMPASSIONOPERATOR";
          req.updateBoolean = newVal;
          break;
        default:
          root.$bvToast.toast(`Message: Error`, {
            title: `Updating ${item.fullName} Failed. ${flag} not supported.`,
            toaster: "b-toaster-bottom-center",
            variant: "danger",
            autoHideDelay: 5000
          });
          return;
      }

      try {
        await updateUser(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.error ? err.response.data.error : err.response.data.message;
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating ${item.fullName} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await initalLoadUsers();
      }
    };

    const facilitiesFiltered = () => {
      return getFacilityList.value.map((p) => {
        return {
          value: p,
          text: p.name
        };
      });
    };

    const onSelectedFacility = () => {
      if (!form.facilityUids) {
        form.facilityUids = [];
      }
      if (form.facilityUids.includes(form.selectedFacility.facilityUid)) {
        return;
      }
      form.facilities.push(form.selectedFacility);
      form.facilityUids.push(form.selectedFacility.facilityUid);
      updateFacListKey.value++;
    };

    const onRemoveFacility = (item) => {
      console.log(item);
      form.facilities = form.facilities.filter((f) => f.facilityUid !== item.facilityUid);
      console.log(form.facilities);
      form.facilityUids = form.facilityUids.filter((f) => f !== item.facilityUid);
      console.log(form.facilityUids);
      updateFacListKey.value++;
    };

    const editItem = async (item) => {
      router.push({ name: "create-user", params: { userRow: item, title: "Update" } });
    };

    const addNewUserItem = () => {
      if (editUserItem.value !== -1 || validateIfDetailsOpen()) {
        return;
      }
      router.push({ name: "create-user" });
    };

    const saveEdited = async () => {
      let pd = {};
      pd.username = form.username;
      pd.userUid = form.userUid || null;
      pd.email = form.email;
      pd.fullName = form.fullName;
      pd.phone = form.phone;
      pd.metrcApiKey = form.metrcApiKey;
      pd.organizationUid = form.organizationUid || null;
      pd.isSupportAgent = form.isSupportAgent;
      pd.isCompassion = form.isCompassion;
      pd.facilityUids = form.facilityUids;
      if (form.orgObject) {
        pd.organizationUid = form.orgObject;
      }
      try {
        await saveEditUserItem(pd);
      } finally {
        editUserItem.value = -1;
        form.username = "";
        form.userUid = "";
        form.email = "";
        form.fullName = "";
        form.phone = "";
        form.metrcApiKey = "";
        form.organizationUid = "";
        form.isSupportAgent = false;
        form.isCompassion = true;
        form.facilityUids = [];
        form.facilities = [];
        form.orgObject = null;
        form.defaultOrg = "";

        await onLoadAllUsers({ prevPage: false, nextPage: false, reset: true });
      }
    };

    const isUserAdmin = () => getUserData && getUserData.admin;

    const onUserTimelineClick = async (row) => {
      await router.push({ name: "user-timeline", params: { user: row } });
    };

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadUsers();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllUsers({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllUsers({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllUsers({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      filterBy.value = "";
      isSortDesc.value = false;
      loadAllOrgs();
    });

    watch(filterBy, (newValue, oldValue) => {
      initalLoadUsers();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadUsers();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadUsers();
    });

    return {
      getUserList,
      getFacilityList,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      onResetPasswordRequest,
      onLoadAllUsers,
      updateUser,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      showDateInFormat,
      onPageChanged,
      requestPasswordReset,
      perPageChanged,
      inactivateUser,
      isUserAdmin,
      addNewUserItem,
      saveEdited,
      editItem,
      onSelectedFacility,
      facilitiesFiltered,
      form,
      editUserItem,
      updateFacListKey,
      onRemoveFacility,
      getAllOrgs,
      onUserTimelineClick,
      selectedUser,
      sortBy,
      sortOrder,
      isSortDesc
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
