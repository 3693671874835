import axiosIns from "@/libs/axios";

export function requestLoadUsers(reqData) {
  return axiosIns.post("/admin/list_users", reqData, {});
}

export function requestCreateUser(reqData) {
  return axiosIns.post("/admin/create_user", reqData, {});
}

export function requestListOrganizations(reqData) {
  return axiosIns.post("/admin/list_organizations", reqData, {});
}

export function requestListFacilities(reqData) {
  return axiosIns.post("/admin/list_facilities", reqData, {});
}

export function requestPasswordResetEmail(reqData) {
  return axiosIns.post("/admin/send_password_reset_email", reqData, {});
}

export function requestCreateOrganization(reqData) {
  return axiosIns.post("/admin/create_organization", reqData, {});
}

export function requestSaveEditOrganization(reqData) {
  return axiosIns.post("/admin/create_edit_organization", reqData, {});
}

export function requestLoadFacilitiesForUser(reqData) {
  return axiosIns.post("/admin/facilities_for_user", reqData, {});
}

export function requestUpdateUser(reqData) {
  return axiosIns.post("/admin/update_user", reqData, {});
}

export function requestSaveEditUser(reqData) {
  return axiosIns.post("/admin/create_edit_user", reqData, {});
}

export function requestListTimelineForUser(reqData) {
  return axiosIns.post("/admin/timeline_for_user", reqData, {});
}

export function loadQueueData(reqData) {
  return axiosIns.post("/dev/crm/queued", reqData, {});
}

export function processContactInQueue(reqData) {
  return axiosIns.post("/dev/crm/serviced", reqData, {});
}

export function loadHOFContactDocsListRequest(reqData) {
  return axiosIns.post("/dev/crm/documents", reqData, {});
}

export function loadHofDocumentImage(reqData) {
  return axiosIns.post("/dev/crm/documentImage", reqData, {});
}
